import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { MenuItem, TextField } from '@mui/material';
import { staffSignupData } from '../../../data/user/staffData';
import EmailInput from '../../../components/form/EmailInput';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import PasswordInput from '../../../components/form/PasswordInput';
import moment from 'moment';
import CountriesInput from '../../../components/form/CountriesInput';
import RolesInput from '../../../components/form/RolesInput';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { createStaffReq } from '../../../controllers/user/createStaff';
import { useNavigate } from 'react-router-dom';
import { updateStaffRole } from '../../../controllers/settings/staff/updateStaffRole';
import { getStaffRoles } from '../../../controllers/settings/staff/getStaffRoles';

export default function Index() {
  const [data,setData] = useState({...staffSignupData,
    confirmPassword: '',
    "traacCostCenterCode": process.env.REACT_APP_traacCostCenterCode || '001',
    "traacDepartmentCode": process.env.REACT_APP_traacDepartmentCode || '004',
    "traacSupplierCode": process.env.REACT_APP_traacSupplierCode || 'B0138',
    "traccStaffEmailId": process.env.REACT_APP_traccStaffEmailId || 'test@nucore',
    "traacAccountCode": process.env.REACT_APP_traacAccountCode,
    "traacAmadeusDxbSupplierCode": process.env.REACT_APP_traacAmadeusDxbSupplierCode || '',
    "traacAmadeusLosSupplierCode": process.env.REACT_APP_traacAmadeusLosSupplierCode,
    "traacAiicoSupplierCode": process.env.REACT_APP_traacAiicoSupplierCode,
    "traacViatorSupplierCode": process.env.REACT_APP_traacViatorSupplierCode,
    "traacMarketPlaceSupplierCode": process.env.REACT_APP_traacMarketPlaceSupplierCode,
  });
  const [loading,setLoading] = useState(false);
  const [roles,setRoles] = useState([]);
  const [role,setRole] = useState('');
  const [loadingRoles,setLoadingRoles] = useState(false);

  const navigate = useNavigate();
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle("Staff"))
    loadRoles()
  },[dispatch])

  let designations = ["travel consultant", "ticketing officer", "booking officer", "customer support", "supervisor"];

  async function createStaff(ev) {
    ev.preventDefault();

    if(data.password !== data.confirmPassword)
      return dispatch(setAlertMsg(['error','Passwords don\'t match!']))

    setLoading(true);
    const res = await createStaffReq(data);
    setLoading(false);
    if(res.return) {
      try {
        handleRoleUpdate(res.data?.id)
        // setUser({id: res.data.id})
      } catch(ex) {}
      dispatch(setAlertMsg(['success','Staff Member Created.']))
      return setTimeout(() => navigate("/staff"),2000);
    } else dispatch(setAlertMsg(['error',res.msg]))
    console.log(data)
  }

  async function handleRoleUpdate(id) {
    const res = await updateStaffRole(id,role)
    if(res.return) {
      // return dispatch(setAlertMsg(['success','Role Updated']))
    }
    else return dispatch(setAlertMsg(['error',res.msg]))
  }

  async function loadRoles() {
    setLoadingRoles(true);
    const res = await getStaffRoles();
    setLoadingRoles(false);
    if(res.return) {
      setRoles(res.data)
    }
  }


  return (
    <div className='w-full p-4 m-2'>
      <form action='' onSubmit={createStaff} className='inline-block'>
        <div className='flex flex-col gap-6 w-auto'>
          <h2>Create a staff</h2>
          <TextField size='small' label='Title' select
            className='self-start min-w-[100px]'
            value={data.title}
            onChange={(ev) => setData({...data,title: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }}>
              <MenuItem value='Mr'>Mr</MenuItem>
              <MenuItem value='Ms'>Ms</MenuItem>
              <MenuItem value='Mrs'>Mrs</MenuItem>
          </TextField>
          <div className='flex gap-4'>
            <TextField size='small' label='Surname' required
              value={data.lastName}
              onChange={(ev) => setData({...data,lastName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='First Name' required
              value={data.firstName}
              onChange={(ev) => setData({...data,firstName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <EmailInput size='small' label='Email' required
              value={data.email}
              onChange={(val) => setData({...data,email: val})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <PhoneNumberInput size='small' label='Phone' required
              value={data.phoneNumber}
              onChange={(val) => setData({...data,phoneNumber: val})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Birth Date' type='date'
              value={moment(data.dob || "").format("yyyy-MM-DD")}
              onChange={(ev) => setData({...data,dob: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          
          <div>
            <TextField select size='small' label='Role' name='role' className='min-w-[200px]'
              value={data?.staffRole?.id}
              onChange={(ev) => setRole(ev.target.value)}
            >
              <MenuItem value=''></MenuItem>
              {roles?.map((obj,i) => (
                <MenuItem key={i} value={obj.id}>{obj.roleName}</MenuItem>
              ))}
            </TextField>
          </div>

          {/* <RolesInput id={user.id} /> */}

          {/* <div className='flex gap-4'>
            <PasswordInput size='small' label='Password' required
              value={data.password}
              onChange={(val) => setData({...data,password: val})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PasswordInput size='small' label='Re-enter Password' required
              value={data.confirmPassword}
              onChange={(val) => setData({...data,confirmPassword: val})}
              />

          </div> */}

          <h5>Employment Details</h5>
          <div className='flex gap-4'>
            <TextField size='small' label='Employee Id' 
              value={data.employeeId}
              onChange={(ev) => setData({...data,employeeId: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Department' 
              value={data.employeeDepartment}
              onChange={(ev) => setData({...data,employeeDepartment: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField select size='small' label='Designation' className='min-w-[160px]'
              value={data.employeeDesignation}
              onChange={(ev) => setData({...data,employeeDesignation: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {designations.map((val,i) => (
                <MenuItem key={i} value={val} className='capitalize'>{val}</MenuItem>
              ))}
            </TextField>
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='National Id' 
              value={data.nationalId}
              onChange={(ev) => setData({...data,nationalId: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='National Id Expiry Date' type='date'
              value={moment(data.nationalIdExpiry || "").format("YYYY-MM-DD")}
              onChange={(ev) => setData({...data,nationalIdExpiry: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <div>
              <TextField size='small' label='Traac Account Code' required
                value={data.traacAccountCode}
                onChange={(ev) => setData({...data,traacAccountCode: ev.target.value})}
                InputLabelProps={{
                  shrink: true,
                }} />

            </div>

          </div>

          <h5>Address Details</h5>
          <div className='flex gap-4'>
            <TextField size='small' label='Nationality' name='nationality'
              value={data.nationality}
              onChange={(ev) => setData({...data,nationality: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <CountriesInput size='small' label='Country' 
              value={data.countryCode}
              onChange={(val) => setData({...data,countryCode: val.name || val})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='City' 
              value={data.cityName}
              onChange={(ev) => setData({...data,cityName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Address One' 
              value={data.addressLine1}
              onChange={(ev) => setData({...data,addressLine1: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Address Two' 
              value={data.addressLine2}
              onChange={(ev) => setData({...data,addressLine2: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <button className='btn2 self-start flex items-center gap-2'>
            {loading? <div className='load'></div>:null}
            Create Staff</button>
        </div>
      </form>
    </div>
  )
}
