import React, { useEffect, useState } from 'react'
import MultipleSelect from '../form/MultiSelect'
import { MenuItem, TextField } from '@mui/material'
import { agentProfileTemp } from '../../data/user/agentProfile'
import PhoneNumberInput from '../form/PhoneNumberInput'
import { clone } from '../../features/utils/objClone'
import { updateSubAgent } from '../../controllers/user/updateSubAgent'
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice'
import { useDispatch } from 'react-redux'
import { paymentTypes } from '../../data/ENUM/PaymentType'
import CurrencyInput from '../form/CurrencyInput'
import { capitalize } from '../../features/utils/capitalize'


const promoptions = [
  {label: 'Facebook',value: 'Facebook'},
  {label: 'Twitter',value: 'Twitter'},
  {label: 'Instagram',value: 'Instagram'},
  {label: 'Linkedin',value: 'Linkedin'},
  {label: 'Snapchat',value: 'Snapchat'},
]
export default function AgentProfile({userData,returnData,label='Agent',resetFields,reload}) {
  const initState = {...agentProfileTemp,promos: promoptions,};
  let [data,setData] = useState(initState);
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  let paymentMethods = paymentTypes;

  if(label==='Corporate')
    paymentMethods = ["Invoice","Deposit"]

  useEffect(() => {
    if(userData) {
      try {
        let {id,...user} = userData.apiUserData;
        if(label.toLowerCase() === 'corporate')
        user = {
          "traacCostCenterCode": process.env.REACT_APP_traacCostCenterCode || '001',
          "traacDepartmentCode": process.env.REACT_APP_traacDepartmentCode || '004',
          "traacSupplierCode": process.env.REACT_APP_traacSupplierCode || 'B0138',
          "traccStaffEmailId": process.env.REACT_APP_traccStaffEmailId || 'test@nucore',
          "traacAccountCode": userData.traacAccountCode || process.env.REACT_APP_traacAccountCode,
          "traacAmadeusDxbSupplierCode": process.env.REACT_APP_traacAmadeusDxbSupplierCode || '',
          "traacAmadeusLosSupplierCode": process.env.REACT_APP_traacAmadeusLosSupplierCode,
          "traacAiicoSupplierCode": process.env.REACT_APP_traacAiicoSupplierCode,
          "traacViatorSupplierCode": process.env.REACT_APP_traacViatorSupplierCode,
          "traacMarketPlaceSupplierCode": process.env.REACT_APP_traacMarketPlaceSupplierCode,
          ...user
        }
        setData(data => ({...agentProfileTemp,...user,promos: data.promos}))
      } catch(ex) {
        try {

          if(label.toLowerCase() === 'corporate') {
            let user = {
              "traacCostCenterCode": process.env.REACT_APP_traacCostCenterCode || '001',
              "traacDepartmentCode": process.env.REACT_APP_traacDepartmentCode || '004',
              "traacSupplierCode": process.env.REACT_APP_traacSupplierCode || 'B0138',
              "traccStaffEmailId": process.env.REACT_APP_traccStaffEmailId || 'test@nucore',
              "traacAccountCode": userData.traacAccountCode || process.env.REACT_APP_traacAccountCode,
              "traacAmadeusDxbSupplierCode": process.env.REACT_APP_traacAmadeusDxbSupplierCode || '',
              "traacAmadeusLosSupplierCode": process.env.REACT_APP_traacAmadeusLosSupplierCode,
              "traacAiicoSupplierCode": process.env.REACT_APP_traacAiicoSupplierCode,
              "traacViatorSupplierCode": process.env.REACT_APP_traacViatorSupplierCode,
              "traacMarketPlaceSupplierCode": process.env.REACT_APP_traacMarketPlaceSupplierCode,
            }
            setData(data => ({...agentProfileTemp,...user,promos: data.promos}))
          }
        } catch(ex) {}

      }
    }
  },[userData,label])
  
  useEffect(() => {
    if(resetFields)
      setData(initState)
    //eslint-disable-next-line
  },[resetFields])

  async function handleSubmit(ev) {
    ev.preventDefault();
    if(!userData) return false;

    let upData = clone(data);
    upData = Object.fromEntries(
      Object.entries(upData).filter(([key, value]) => value !== null && value !== undefined)
    );

    setLoading(true);
    const res = await updateSubAgent(upData,data.userId || data.id || userData.id);
    setLoading(false);
    if(res.return) {
      reload && reload(res);
      return dispatch(setAlertMsg(['success',userData?'Profile updated.':'Agent created.']));
    }
    else return dispatch(setAlertMsg(['error',res.msg]))

  }
  console.log(data)

  function handleChange(val) {
    setData(val);
    if(returnData)
      returnData(val)
  }

  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-10 max-w-[800px]'>
      <div className='flex flex-col gap-6 w-auto'>
        {userData ? (
          <h2>{capitalize(label?.toLowerCase() || label)} Profile</h2>
        ):(
          <h2>Create {label}</h2>
        )}

        <TextField size='small' label={label==='Corporate' ? 'Company Name':'Agency Name'} name='companyName'
          value={data.companyName}
          onChange={(ev) => handleChange({...data,companyName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        {label?.toLowerCase() === 'corporate' ? (
          <div className='flex gap-4'>
            <TextField size='small' label='Traac Account Code' required
              value={data.traacAccountCode}
              onChange={(ev) => handleChange({...data,traacAccountCode: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            {/* <TextField size='small' label='CDR'
              value={data.CDR}
              onChange={(ev) => handleChange({...data,CDR: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} /> */}
            <MultipleSelect freeSolo label='CDR'
              className='flex-1'
              value={data.CDR}
              onChange={(val) => handleChange({...data,CDR: val})} 
              InputLabelProps={{
                shrink: true,
              }} />

          </div>
        ):null}


        <TextField label="Company description" multiline rows={4}
          value={data.companyDescription} 
          onChange={(ev) => handleChange({...data,companyDescription: ev.target.value})} 
        />
        <div className='flex gap-4'>
          {label === 'Agent' ? (
            <TextField size='small' label='CAC Reg No' name='cacRegNo'
              value={data.cacRegNo}
              onChange={(ev) => handleChange({...data,cacRegNo: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          ):null}
          <TextField size='small' label={label==='Corporate' ? 'Company Location':'Agency Location'} name='location'
            value={data.location}
            onChange={(ev) => handleChange({...data,location: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />

        </div>

        <div className='flex gap-4'>
          <TextField size='small' label='Contact Surname' 
            value={data.contactSurName}
            onChange={(ev) => handleChange({...data,contactSurName: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
          <TextField size='small' label='Contact First Name' 
            value={data.contactFirstName}
            onChange={(ev) => handleChange({...data,contactFirstName: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
        </div>
        <div className='flex gap-4'>
          <TextField size='small' label='Contact Email' 
            value={data.contactEmail}
            onChange={(ev) => handleChange({...data,contactEmail: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
        </div>
        {label !== 'CORPORATE' ? (
          <div className='flex gap-4 flex-wrap'>
            <TextField size='small' label='Sales Manager' name='salesManager'
            className='flex-1'
            value={data.salesManager}
            onChange={(ev) => handleChange({...data,salesManager: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
            <TextField size='small' label='Payment Type' name='paymentType' select
            className='min-w-[200px] flex-1'
            value={data.paymentType}
            onChange={(ev) => handleChange({...data,paymentType: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }}>
              <MenuItem value=""></MenuItem>
              {paymentMethods.map(val => (
                <MenuItem value={val} className='capitalize'>{val.toLowerCase()}</MenuItem>
              ))}
            </TextField>
            <CurrencyInput value={data.currency} onChange={(val) => handleChange({...data,currency: val})} className="flex-1" />
          </div>
        
        ):null}
      </div>

      {(label === 'Agent' || label === "SUB_AGENT") ? (
        <div className='flex flex-col gap-6 w-auto'>

        <div className='flex flex-col gap-3'>
          <h2>Telephone Number</h2>
          <div className='border border-[#777] rounded-md flex overflow-hidden'>
            <div className='w-[50px] px-4 py-2 bg-primary/30 text-center'>1</div>
            <div className='flex-1'>
              <PhoneNumberInput label=""
              value={data.contactPhone}
              onChange={(val) => handleChange({...data,contactPhone: val})}
              />
              {/* <input type='text' className='border-0 p-2 w-full'
              value={data.contactPhone}
              onChange={(ev) => handleChange({...data,contactPhone: ev.target.value})}
              /> */}
            </div>
          </div>
          <div className='border border-[#777] rounded-md flex overflow-hidden'>
            <div className='w-[50px] px-4 py-2 bg-primary/30 text-center'>2</div>
            <div className='flex-1'>
              {/* <input type='text' className='border-0 p-2 w-full' */}
              <PhoneNumberInput label=""
              value={data.contactPhone2}
              onChange={(val) => handleChange({...data,contactPhone2: val})}
              />
            </div>
          </div>
        </div>
          
        <div className='flex flex-col gap-3'>
          <div className=''>
            <MultipleSelect label={'Promotion Options'} options={promoptions}
              value={data.promos}
              onChange={(val) => handleChange({promos: val})}
            />
          </div>
          <label className='flex items-center cursor-pointer gap-2'><input type='radio' name='prom' 
            onClick={() => handleChange({...data,receivePromotion: true})}
            /> I am happy to receive promotions via all the above</label>
          <label className='flex items-center cursor-pointer gap-2'><input type='radio' name='prom' 
            onClick={() => handleChange({...data,receivePromotion: false})}
          /> I dont want to receive promotions via all the above</label>
        </div>

      <div className='flex flex-col gap-3'>
        <h6>How did you hear about us:</h6>
        <TextField size='small' className='w-full'
         value={data.referalDescription}
         onChange={(ev) => handleChange({...data,referalDescription: ev.target.value})}
        />
      </div>

      <div className='flex flex-col gap-4'>
        <h6>If you were referred by a member of the JourneyEasyTM family please complete the section below</h6>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>JourneyEasyTM User Name:</span>
          <TextField size='small' className='' 
           value={data.referalUsername}
           onChange={ev => handleChange({...data,referalUsername: ev.target.value})} />
        </div>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>Relationship with new JourneyEasyTM Family member:</span>
          <TextField size='small' className='' 
           value={data.referalRelationShip}
           onChange={ev => handleChange({...data,referalRelationShip: ev.target.value})} />
        </div>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>JourneyEasyTM Account Number:</span>
          <TextField size='small' className='' 
           value={data.referalAccountNumber}
           onChange={ev => handleChange({...data,referalAccountNumber: ev.target.value})} />
        </div>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>Earn Points together?</span>
          <TextField size='small' className='min-w-[200px]' select
            value={data.earnPointsTogether}
            onChange={(ev) => handleChange({...data,earnPointsTogether: ev.target.value})}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </TextField>
        </div>
      </div>
      </div>
      ):null}
      {data.promos.length > 0 && label==='Agent' && (
        <div className='flex flex-col gap-3'>
          <h2>Setup your connections</h2>
          <p>Let’s get you all set up so you can access your personal account.</p>
          <h6>Social Media Handles</h6>
          <div className='flex flex-col gap-4'>
            <div className={`border border-[#777] rounded-md overflow-hidden ${data.promos.find(obj => obj.value === 'Facebook') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Facebook</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.facebookHandle}
                 onChange={(ev) => handleChange({...data,facebookHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md overflow-hidden ${data.promos.find(obj => obj.value === 'Twitter') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Twitter</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.twitterHandle}
                 onChange={(ev) => handleChange({...data,twitterHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md flex overflow-hidden ${data.promos.find(obj => obj.value === 'Instagram') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Instagram</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.instagramHandle}
                 onChange={(ev) => handleChange({...data,instagramHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md flex overflow-hidden ${data.promos.find(obj => obj.value === 'Linkedin') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Linkedin</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.linkedInHandle}
                 onChange={(ev) => handleChange({...data,linkedInHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md flex overflow-hidden ${data.promos.find(obj => obj.value === 'Snapchat') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Snapchat</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.snapchatHandle}
                 onChange={(ev) => handleChange({...data,snapchatHandle: ev.target.value})}
                 />
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        {
          userData ? 
            <button className='btn2'>
              {loading ? (<div className='load'></div>):null}
                Update Profile
              </button>
          : null
        }
      </div>

    </form>
  )
}
