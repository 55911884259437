import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { Breadcrumbs, TextField } from '@mui/material';
import { agentSignupTemp } from '../../../data/user/agentSignupData';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import AgentProfile from '../../../components/user/EditAgentProfile';
import { clone } from '../../../features/utils/objClone';
import { createCorporate } from '../../../controllers/user/createCorporate';
import { Link } from 'react-router-dom';
import { updateCorporate } from '../../../controllers/user/updateCorporate';
import FlightServiceCharge from '../../../components/serviceCharge/FlightServiceCharge';
import HotelServiceCharge from '../../../components/serviceCharge/HotelServiceCharge';
import TourServiceCharge from '../../../components/serviceCharge/TourServiceCharge';


export default function Index() {
  // const [markupType,setMarkupType] = useState("");
  // const [markupValue,setMarkupValue] = useState(0);
  // const [provider,setProvider] = useState("");
  // const [flightServiceFee,setFlightServiceFee] = useState({
  //   markupType: "",
  //   markupValue: 0,
  //   provider: '',
  //   flightType: '',
  // })
  // const [hotelServiceFee,setHotelServiceFee] = useState({
  //   markupType: "",
  //   markupValue: 0,
  // })
  // const [tourServiceFee,setTourServiceFee] = useState({
  //   markupType: "",
  //   markupValue: 0,
  // })
  const [updateFee,setUpdateFee] = useState(false);
  const [updateFeeHotel,setUpdateFeeHotel] = useState(false);
  const [updateFeeTour,setUpdateFeeTour] = useState(false);
  const [userData,setUserData] = useState();
  const [resetField,setResetField] = useState(0);

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const initState = {...agentSignupTemp,
    password: 'password',
    confirmPassword: 'password',
    "traacCostCenterCode": process.env.REACT_APP_traacCostCenterCode || '001',
    "traacDepartmentCode": process.env.REACT_APP_traacDepartmentCode || '004',
    "traacSupplierCode": process.env.REACT_APP_traacSupplierCode || 'B0138',
    "traccStaffEmailId": process.env.REACT_APP_traccStaffEmailId || 'test@nucore',
    "traacAccountCode": process.env.REACT_APP_traacAccountCode,
    "traacAmadeusDxbSupplierCode": process.env.REACT_APP_traacAmadeusDxbSupplierCode || '',
    "traacAmadeusLosSupplierCode": process.env.REACT_APP_traacAmadeusLosSupplierCode,
    "traacAiicoSupplierCode": process.env.REACT_APP_traacAiicoSupplierCode,
    "traacViatorSupplierCode": process.env.REACT_APP_traacViatorSupplierCode,
    "traacMarketPlaceSupplierCode": process.env.REACT_APP_traacMarketPlaceSupplierCode,
    "CDR": '',
  }
  const [data,setData] = useState(initState);
  const [loading,setLoading] = useState(false);

  // console.log(updateFee,data)

  useEffect(() => {
    dispatch(setHeaderTitle("Corporates"))
  },[dispatch])

  useEffect(() => {
    if(userData) {
      setUpdateFee(true);
      setUpdateFeeHotel(true);
      setUpdateFeeTour(true);
    }
  },[userData])
  
  async function handleCreate() {
    if(data.password !== data.confirmPassword)
      return dispatch(setAlertMsg(['error','Passwords dones\'nt match!']));
      
      setLoading(true);
      const res = await createCorporate(data)
      setLoading(false);
      if(res.return) {
        setUserData(res?.data)
        // handleFlightServiceFee(res?.data?.id);
        // handleHotelServiceFee(res?.data?.id);
        // handleTourServiceFee(res?.data?.id);
      }
      else dispatch(setAlertMsg(['error',res.msg]))

      return res;
  }


  async function updateProfile(userId) {
    let result = {return: 0,msg: 'Error'};
    try {

      let upData = clone(data);
      upData = Object.fromEntries(
        Object.entries(upData).filter(([key, value]) => value !== null && value !== undefined)
      );

      setLoading(true);
      const res = await updateCorporate(upData,userId);
      setLoading(false);
      if(res.return) {
        result = {return: 1,msg: 'success'};
        // return dispatch(setAlertMsg(['success','Profile updated.']));
      }
      else return dispatch(setAlertMsg(['error',res.msg]))
    } catch(ex) {dispatch(setAlertMsg(['error','Error! (OxUPDP)']))}
    
    return result;
  }

  function handleInputChange(obj) {
    setData({...data,...obj})
  }

  async function handleSubmit(ev) {
    ev.preventDefault();

    const createRes = await handleCreate();
    if(createRes.return) {
      const res = await updateProfile(createRes.data.id)
      if(res.return) {
        dispatch(setAlertMsg(['success','Agent Creation Successfull.']))
        // navigate('/corporates');
        setData(initState)
        setResetField(a => a+1);
      } else dispatch(setAlertMsg(['warning','Agent Created but profile was not updated! please update the profile from details page']))
    }
  } 
  return (
    <form action='' onSubmit={handleSubmit} className='w-full p-4 m-2 flex flex-col gap-6'>
      <Breadcrumbs separator="/" className='text-theme1'>
        <Link to="/corporates">Corporates</Link>
        <h5 className='text-primary/60'>Create</h5>
      </Breadcrumbs>
      <AgentProfile label='Corporate' returnData={(subAgent) => setData({...data,...subAgent,email: subAgent.contactEmail})} resetFields={resetField} />
      <div className='flex gap-4'>
        <TextField size='small' label='Traac Account Code' required
          value={data.traacAccountCode}
          onChange={(ev) => handleInputChange({traacAccountCode: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField size='small' label='CDR'
          value={data.CDR}
          onChange={(ev) => handleInputChange({CDR: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />

      </div>
      <div className='flex gap-4'>
        <TextField size='small' label='Surname' required
          value={data.lastName}
          onChange={(ev) => handleInputChange({lastName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField size='small' label='First Name' required
          value={data.firstName}
          onChange={(ev) => handleInputChange({firstName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <PhoneNumberInput label="User Phone" required
          value={data.phoneNumber}
          onChange={(val) => handleInputChange({phoneNumber: val})}
          />
      </div>
      <br />
      <h4>Flight Service Fee</h4>
      <FlightServiceCharge companyData={userData} submit={updateFee} handleReturn={() => setUpdateFee(false)} resetFields={resetField} />

      <h4>Hotel Service Fee</h4>
      <HotelServiceCharge companyData={userData} submit={updateFeeHotel} handleReturn={() => setUpdateFeeHotel(false)} resetFields={resetField} />

      <h4>Tour Service Fee</h4>
      <TourServiceCharge companyData={userData} submit={updateFeeTour} handleReturn={() => setUpdateFeeTour(false)} resetFields={resetField} />

      <button className='btn2 self-start mb-10' disabled={loading}>
        {loading ? (
          <div className='load'></div>
        ) : null}
        Create Corporate</button>

    </form>
  )
}
